import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';
import { CookieService } from 'ngx-cookie-service';
import { LoadingService } from './loading.service';
import jwt from "jwt-decode";
import { ApiEndpointService } from './api-endpoint.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class IndexResolver implements Resolve<boolean> {
  access_token: any;
  refresh_token: any;
  userName: any;
  candidateData: any;
  constructor(
    private _router: Router,
    private cookieService: CookieService,
    private tokenService: TokenService,
    private loadingService: LoadingService,
    private apiService: ApiEndpointService,
    @Inject(DOCUMENT) private document: Document
    // private encryptLss: EncryptLocalstorageService
  ) {
  }
  async resolve() {
    this.loadingService.showLoader();
    let responseCode;
    const url = window.location.href.split('/?');
   debugger
    if (url.length > 1) {
      const codeUrl = url[1].split('=');
      responseCode = codeUrl[1];

      // to get meeting url and redirect
      if (codeUrl[0] === 'mid') {

        const meetingid = codeUrl[1];
        // this.cookieService.set(
        //   'CognitoIdentityServiceProvider.4nv8h0tbaabe9ijs0uuqs925td.5dd2c06c-7c5d-423a-86fd-d840a81b049d.idToken',
        //   "eyJraWQiOiJqVlwvdkJscmRiZEdGdU1EU2dFK3lwaWdwb3A0UVVIVEU2c0JjazlUaUJWaz0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiazg5RnlLWUVEUGh2c2hCWGZEc0pvdyIsInN1YiI6IjM0NDc1YmU3LTRiMjgtNGMzNy1hMDFmLWEyMGEyMzMxZTg1YyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX3N0Wm5sM09HQiIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImNvZ25pdG86dXNlcm5hbWUiOiIzNDQ3NWJlNy00YjI4LTRjMzctYTAxZi1hMjBhMjMzMWU4NWMiLCJhdWQiOiIzYzFhcnFzZnBudjhkZmFqbTExYm43MmVmcSIsImV2ZW50X2lkIjoiMGQ0M2FhZmQtMzdlZi00OWM1LWFkYmItMDFhOWI1OGJiOGY4IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MjY3Njc3NjksInBob25lX251bWJlciI6Iis5MTc4NDAwNzkwMjgiLCJleHAiOjE2MjY3NzEzNjksImlhdCI6MTYyNjc2Nzc2OSwiZW1haWwiOiJuYXJlbmRlci5zaW5naEBoaXRlY2hwZW9wbGVpbmMuY29tIn0.Gcja2At06E8ffvVPJ5vTJVsVz9gYuidL8hXlhGHQRJLhhIuf8uVHlGpaTkofSCC2W7z_XpWJchXKLMduNaNMphaItLxv6BXMUOUGee3-GFPZyJiWJbxgofNdx_NUuocHrMZtb0wqbO1dzOBTv0qbMBVugs_y39CJgEqI1RIcuCfrJ43lMIun8b_r33vi1_kz_5_y2S4BI9fDjWSG55nYnBKEoD3QksApRkqRDbrThVu75S-j3YVWHd_VnODh47S_w3RsjcakLNqnv5sICMoUf0TLCtmG0Cpmoq1qVp7jxA1bzr77ytFU4Iw4OF768MgkqX-dZR5Z8xCLuNSs3X4M3A");
        // this.cookieService.set(
        //   'CognitoIdentityServiceProvider.4nv8h0tbaabe9ijs0uuqs925td.5dd2c06c-7c5d-423a-86fd-d840a81b049d.accessToken',
        //   "eyJraWQiOiIySXBnSFdwb21vT2tNcDloRVl4QkhSV1J1VnNJZ1pSdXFkSDlhMTNrZG9zPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzNDQ3NWJlNy00YjI4LTRjMzctYTAxZi1hMjBhMjMzMWU4NWMiLCJldmVudF9pZCI6IjBkNDNhYWZkLTM3ZWYtNDljNS1hZGJiLTAxYTliNThiYjhmOCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4gcGhvbmUgZ2xvYmFsaHVudGluZGlhLmNvbVwvYXBpIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjI2NzY3NzY5LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX3N0Wm5sM09HQiIsImV4cCI6MTYyNjc3MTM2OSwiaWF0IjoxNjI2NzY3NzY5LCJ2ZXJzaW9uIjoyLCJqdGkiOiI4OTkyNmVmMS05MWNjLTRhMzMtYjdjNy00ZDhhMzg2M2YxZmEiLCJjbGllbnRfaWQiOiIzYzFhcnFzZnBudjhkZmFqbTExYm43MmVmcSIsInVzZXJuYW1lIjoiMzQ0NzViZTctNGIyOC00YzM3LWEwMWYtYTIwYTIzMzFlODVjIn0.kcG-zYg1OwyauJktaUnBs6Q02769ExACISGEfPrSlbiCBNbsw6HQaemeyM0nBf_7SZMl6o3cNtRmO6pCrCDXHEhHTsKqxozwDpkdJqcI-tIyok9ugYO48bxnvhYzL6y4mRYQ0trgeQxDRAZ2MiOkYfM4IDetT8KsFWO17IfuPIiO1zo1E9YJ46k3NY6yO7tZvFs_rZBY5HZkKHCmhcvIp0qjyFE02eK27ia2Myz95CQVh2cJS7cjknZA5Yi1AdhPjqbNIOJFiWta3WCdaU_JAh30okvUSZ0Kf0PMww9IDsdpbUrlSnQeKdX24Z2NPqp83EVKPA7SKDCp6U77f_u4yg");
        var accesstoken;
        var id_token;
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          if (cookie.includes("accessToken") == true) {
            accesstoken = cookie.split("=")[1];
          } else if (cookie.includes("idToken") == true) {
            id_token = cookie.split("=")[1];
          }
        }
       const userData: any = jwt(id_token);
        this.cookieService.set('_at', accesstoken);
       const candidateEmail = userData.email;
        localStorage.setItem('candidateEmail', candidateEmail);
        await this.apiService.getCandidateOnMeetingID(meetingid).then(res => {
          const candidateData = res.dbData;
          if(candidateData.interviewtype=="LiveInterview"){
            alert("work in Progress..")
            window.location.href = `${environment.externalPortal}`;
          }
          if (candidateData.emailid === candidateEmail && candidateData.status != 'Completed' && candidateData.deleted != 1) {
            localStorage.setItem('interviewDateTime', candidateData.startdate);
            localStorage.setItem('interviewername', candidateData.candidatename);
            localStorage.setItem('interviewId', candidateData.interviewid);
            this._router.navigate(['candidate/check-interview']);
          }
          if (candidateData.status === 'Completed') {
            localStorage.setItem('status', candidateData.status);
            this._router.navigateByUrl('candidate/meeting-notfound');
          }
          if (candidateData.deleted === 1) {
            localStorage.setItem('deletedValue', candidateData.deleted);
            this._router.navigateByUrl('candidate/meeting-notfound');
          }
          if (candidateData.emailid != candidateEmail) {
            localStorage.setItem('emailid', candidateData.emailid);
            this._router.navigateByUrl('candidate/meeting-notfound');
          }
        });

      }
      else {
        // else flow will goes as recruiter

        await this.tokenService.getToken(responseCode).then(newdata => {
          if (newdata) {
            this.cookieService.set('unicode', responseCode, { domain: environment.domain });
            this.cookieService.set('_at', newdata.access_token, { domain: environment.domain });
            this.cookieService.set('_appauth', newdata.access_token, { domain: environment.domain });
            const refreshTok = {
              "type": "POST",
              "data": {
                "IsAuthenticated": true,
                "code": this.cookieService.get('unicode'),
                "token": newdata.refresh_token,
                "dateCreated": new Date(Date.now()),
                "dateUpdated": new Date(Date.now())
              }
            }
            this.tokenService.refreshToken(refreshTok).then(data => {
              if (data === 'success') {
              }
            });

          }
        });
        await this.tokenService.getUserLoginDetails(this.cookieService.get('_at')).then(userData => {
          if (userData && Object.keys(userData).length !== 0) {
            window.localStorage.setItem("email", userData.email);
            window.localStorage.setItem('userName', userData.email.split("@")[0]);
            window.localStorage.setItem('phone_number', userData.phone_number);
            const role = userData['custom:role'];
            if (role) {
              //const data = this.encryptLss.encryptData(role);
              window.localStorage.setItem('_ia', role);
            }
          }
        });
        this._router.navigate(['/app']);
        this.hideLoader();

        this.loadingService.startTimer();
        return true;
      }

    }
    else {
      if (this.cookieService.get('unicode') && this.cookieService.get('_at')) {
        this._router.navigate(['/app']);
        this.hideLoader();
        this.loadingService.startTimer();
        return true;
      }
    }
  }
  hideLoader() {
    this.loadingService.hideLoader();
  }
}
