// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  client_id: "3c1arqsfpnv8dfajm11bn72efq",
  aws_cognito_identity_pool_id:
    "ap-south-1:ef59d210-6023-43c5-bf10-dacf197039b6",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_stZnl3OGB",
  domain: "globalhuntindia.com",
  redirect_uri: "https://h8.globalhuntindia.com/",
  socialRedirect_URI: "signindev.globalhuntindia.com",
  response_type: 'code',
  refreshtokenKey: "kJfsNl8vSiPH2uaNVCuPaFs8GWfLSBka0JaHYA7h",
  /* api urls */
  tokenEndPoint: "https://signindev.globalhuntindia.com/",
  refreshToken: "https://devapis.globalhuntindia.com/ref/cup",
  apiUrl: "https://hireapi.globalhuntindia.com/api/Video",
  crmJobApi: "https://devapis.globalhuntindia.com",
  externalPortal:'https://dev.globalhuntindia.com/#/home',
  apiStartRec:"https://jpbfo2nem3.execute-api.ap-south-1.amazonaws.com/Prod/recording?recordingAction=start&meetingURL=",
  apiEndRec : "https://jpbfo2nem3.execute-api.ap-south-1.amazonaws.com/Prod/recording?recordingAction=stop&taskId=",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
