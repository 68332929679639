/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FeedbackComponent } from './home/feedback/feedback.component';
import { OnewayInterviewComponent } from './home/oneway-interview/oneway-interview.component';

import { RedirectComponent } from './redirect/redirect.component';
import { IndexResolver } from './services/index-resolver.service';


const routes: Routes = [

  { path: '', redirectTo: 'redirecting', pathMatch: 'full' },
  { path: 'redirecting', component: RedirectComponent, resolve: { token: IndexResolver } },
  {path: 'oneway-interview',component: OnewayInterviewComponent},
  {path: 'feedback',component: FeedbackComponent},
  
  {
    path: 'app',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'candidate',
    loadChildren: () => import('./candidate/candidate.module').then(m => m.CandidateModule),
    
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
