import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SkipInterceptorService } from './skip-interceptor.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiEndpointService {

  constructor(private http: HttpClient, private skipInterceptor: SkipInterceptorService) { }


  getInterview() {
    return this.http.get(`${environment.apiUrl}/GetInterviews`)
  }

  getQuestionSet() {
    return this.http.get(`${environment.apiUrl}/GetQuestionSet`)
  }


  deletQuestionSet(id, usrid, unm) {
    let body = {};
    return this.http.put(`${environment.apiUrl}/DeleteQuestionSetPost/${id}/${usrid}/${unm}`, body)
  }

  getLiveInterview() {
    return this.http.get(`${environment.apiUrl}/GetInterviewBytype/LiveInterview`)
  }

  getQues(mid) {
    return this.http.get(`${environment.apiUrl}/GetQuestionByRequestId/${mid}`)
  }

  createChimeMeeting(mid) {
    return this.http.get(`${environment.apiUrl}/CreateMeeting/${mid}`)
  }
  createattendee(mid) {
    return this.http.get(`${environment.apiUrl}/CreateAttendee/${mid}`)
  }
  EndMeeting(mid) {
    return this.http.get(`${environment.apiUrl}/EndMeeting/${mid}`)
  }
  getOneWayInterview() {
    return this.http.get(`${environment.apiUrl}/GetInterviewBytype/oneway`)

  }
  deleteInterview(id) {
    return this.http.delete(`${environment.apiUrl}/DeleteInterview/${id}`)
  }
  GetAllLocaljobs() {
    return this.http.get(`${environment.apiUrl}/GETjob`)
  }
  // post Local Job
  createLocalJob(body) {
    return this.http.post(`${environment.apiUrl}/addjob`, body);
  }
  createQuestionSet(questionName, userName) {
    const quesid = "null";
    let body = {};
    return this.http.post(`${environment.apiUrl}/InsertQuestionSetPost/${quesid}/${questionName}/1/${userName}`, body);

  }
  GetQuestionByQuestionSetId(id) {
    return this.http.get(`${environment.apiUrl}/GetQuestionByQuestionSetId/${id}`)
  }
  createNewQuestionSet(quesid,questionName, answerTime, thinkTime, QuestionSetId, userName) {
   
    let body = {};
    return this.http.post(`${environment.apiUrl}/InsertQuestionPost/${quesid}/${questionName}/${answerTime}/${thinkTime}/1/${QuestionSetId}/${userName}`, body);

  }
  getCandidateOnMeetingID(meetingid) {
    return this.skipInterceptor.getRequest(`${environment.apiUrl}/VideoInterview/${meetingid}`);
  }
  DeleteQuestion(id, del, usernm, quesSetid) {
    let body = {};
    return this.http.put(`${environment.apiUrl}/DeleteQuestionPost/${id}/${del}/${usernm}/${quesSetid}`, body)
  }

  InterviewScheduleOneway(body){
    return this.http.post(`${environment.apiUrl}/CreateRequest2`, body);
  }

  LiveInterviewSchedule(body){
    return this.http.post(`${environment.apiUrl}/LiveInterview`, body);
  }

  startRecording(meetingUrl){
    let body = {};
    let url=`${environment.apiStartRec}${meetingUrl}`
    return this.skipInterceptor.postRequest(url,body);
  }

  stopRecording(arnurl){
    let body = {};
    let url=`${environment.apiEndRec}${arnurl}`
    return this.skipInterceptor.postRequest(url,body)
  }

  getCrmJobs(data: any, page) {
    let headers = new HttpHeaders();
    headers = headers.append('apiKey', 'crmjob');
  return this.http.post(`${environment.crmJobApi}/ces/suggestedcandidate/job?perpage=7&next=${page}&sortDate=createddated&source=unit`, data, { headers});
  }

  stopstartrecord(body) {
    return this.http.post(`${environment.apiUrl}/stopChimeRecording`,body)
  }

  checkUrl(url){
    return this.skipInterceptor.getRequest(url);
  }

  QuitInterview(mid){
    let body = {};
    return this.http.post(`${environment.apiUrl}/QuitMeeting/${mid}`,body)
  }

  RemoveQuestion(mid,id){
    let body = {};
    return this.http.post(`${environment.apiUrl}/RemoveAttemptedQuestion/${mid}/${id}`,body)
  }

  Feedback(mid,note,usernm){
    let body = {};
    return this.http.post(`${environment.apiUrl}/InterviewFeedback/${mid}/${note}/${usernm}`,body)
  }

  CrmjobLocal( body){
    return this.http.post(`${environment.apiUrl}/addCrmjobInlocal`,body)
  }
  
}
