import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEndpointService } from '../../services/api-endpoint.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
mid:string;
  constructor(private router: Router, private apiendpoint: ApiEndpointService,) { }

  ngOnInit() {
    const params = new URLSearchParams(window.location.search)
    this.mid = params.get('mid');
  }
  submitFeedback(){
    //alert(this.mid)
    debugger
    var text= ((document.getElementById("textfeedbck") as HTMLInputElement).value);
    if(text.trim()==""){
      alert("please give feedback..")
    }else{
      //alert(text)
      let usernm=localStorage.getItem("userName");
      this.apiendpoint.Feedback(this.mid,text,usernm).subscribe((res:any)=>{
       alert("Thanks For your feedback!!")
       window.location.href = `${environment.externalPortal}`;
      })
    }
 
  }

}
