import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicSelectableModule } from 'ionic-selectable';
import { CreateJobPopoverComponent } from './home/popover-component/create-job-popover/create-job-popover.component';
import { SplitLayoutPage } from './home/common-component/split-layout/split-layout.page';
import { ApiEndpointService } from './services/api-endpoint.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
// import 'chartjs-plugin-zoom';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2FilterPipeModule } from 'ng2-filter-pipe';
import {OrderModule} from 'ngx-order-pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderService } from './services/loader.service';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [AppComponent,
     CreateJobPopoverComponent,
   
      SplitLayoutPage],
  entryComponents: [],
  imports: [BrowserModule,
     IonicModule.forRoot(),
      AppRoutingModule,
     
    NgxSpinnerModule,
     HttpClientModule,
     NgxPaginationModule,
     Ng2FilterPipeModule ,
     OrderModule,
    // IonicSelectableModule,
      FormsModule,
      ReactiveFormsModule,
    BrowserAnimationsModule],
  providers: [
    ApiEndpointService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    CookieService,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
