import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-create-job-popover',
  templateUrl: './create-job-popover.component.html',
  styleUrls: ['./create-job-popover.component.scss'],
})
export class CreateJobPopoverComponent implements OnInit {
  dropDownlist =[
    {
      title: 'Create Local Job',
      url: '/app/interview/createLocalJob'
    },
    {
      title: 'Create Job from ATS',
      url: '/app/interview/its-jobs'
    }
  ];
  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  _dismiss()
  {
    this.popoverController.dismiss();
  }

}
