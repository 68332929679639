import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import jwt from "jwt-decode";
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  refershTokenKey: string;
  isLoading = false;
  timeLeft: any;
  interval: NodeJS.Timeout;
  decoded: any;
  expires: Date;
  constructor(private readonly cookieService: CookieService,
    private readonly tokenService: TokenService,
    private spinner: NgxSpinnerService
  ) { }
  showLoader() {
    this.spinner.show();
}

hideLoader() {
    this.spinner.hide();
  
}
  async startTimer() {
    this.decoded = jwt(this.cookieService.get('_at'));
    this.expires = new Date(this.decoded.exp * 1000)
    let milisec = this.expires.getTime() - Date.now() - (60 * 1000);
    this.interval = setInterval(() => {
      this.refreshToken();
    }, milisec)
  }

  async refreshToken() {
    await this.getRefreshToken();
    await this.tokenService.getRefreshToken(this.refershTokenKey).then(data => {
      if (data.access_token) {
        this.cookieService.set('_at', data.access_token, { domain: environment.domain });
        this.cookieService.set('_appauth', data.access_token, { domain: environment.domain });
        this.startTimer();
      }
    });
  }

  async getRefreshToken() {
    this.refershTokenKey = '';
    const body = {
      "data": {
        "query": {
          "term": {
            "code.keyword": {
              "value": this.cookieService.get('unicode')
            }
          }
        }
      },
      "type": "GET"
    }
    await this.tokenService.refreshToken(body).then(data => {
      if (data[0]._source.token) {
        this.refershTokenKey = data[0]._source.token;
      }
    });
  }
}
