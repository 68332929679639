import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.cookieService.get('_at');
    if (req.headers.has('apiKey')) {
      const apiKey = req.headers.get('apiKey');
    if (apiKey == "crmjob") {
      var headers = req.headers.set('Authorization', `Bearer ${token}`);
    }
  }else{
   var headers = req.headers.set('accesstoken', `${token}`);
  }
    // const headers = req.headers.set('accesstoken', `${token}`);
    const authReq = req.clone({ headers });
    return next.handle(authReq)
      .pipe(catchError((error, caught) => {
        console.log('Error Occurred');
        console.log(error);
        return throwError(error);
      })) as any;
  }
}
