import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import * as $ from 'jquery'
//import { Observable } from 'rxjs';
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSessionStatusCode,
  DefaultAudioVideoController,
} from 'amazon-chime-sdk-js';
import { ApiEndpointService } from '../../services/api-endpoint.service';
import { LoaderService } from '../../services/loader.service';
@Component({
  selector: 'app-oneway-interview',
  templateUrl: './oneway-interview.component.html',
  styleUrls: ['./oneway-interview.component.scss'],
})
export class OnewayInterviewComponent implements OnInit {
  meetiingObj: any;
  attendeeObj: any;
  logger: any
  deviceController: any;
  meetingSession: any;
  localVideo: boolean;
  midt: any;
  mid: any;
  name: string;
  hid: boolean = true;
  constructor(private http: HttpClient,
    private loader: LoaderService,
    private apiendpoint: ApiEndpointService,
    private router: Router,
    ) {
    //  this.mid=localStorage.getItem("mid");
    //  this.name=localStorage.getItem("name");
    const params = new URLSearchParams(window.location.search)
    this.midt = params.get('mid');
    this.initialize();

  }
  ngOnInit() {

  }

  async initialize() {
    //debugger;
    this.logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
    this.deviceController = new DefaultDeviceController(this.logger);

    ////debugger;
    await this.initailizeMeeting();

  }
  async initailizeMeeting() {
    this.apiendpoint.createChimeMeeting(this.midt).subscribe((response) => {
      console.log(response)
      this.meetiingObj = response;
      console.log(this.meetiingObj.Meeting.MeetingId)
      this.getAttendee();
    });
  }

  async getAttendee() {
    ////debugger;
    this.mid = this.meetiingObj.Meeting.MeetingId;
    this.apiendpoint.createattendee(this.mid).subscribe((response) => {
      console.log(response)
      this.attendeeObj = response;
      //s console.log( this.meetiingObj.Meeting.MeetingId)
      this.start();
    });
  }
  async Endmeeting() {
    ////debugger
    this.apiendpoint.EndMeeting(this.mid).subscribe((response) => {
      console.log(response)
    });
  }

  async start() {
    // You need responses from server-side Chime API. See below for details.
    const meetingResponse = this.meetiingObj;
    const attendeeResponse = this.attendeeObj;
    const configuration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);

    // In the usage examples below, you will use this meetingSession object.
    this.meetingSession = new DefaultMeetingSession(
      configuration,
      this.logger,
      this.deviceController,

    );
    // this.meetingSession.audioVideo.startLocalVideoTile();
    //case1

    const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
    const audioOutputDevices = await this.meetingSession.audioVideo.listAudioOutputDevices();
    // const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();

    //debugger;
    // audioInputDevices.forEach(mediaDeviceInfo => {
    //   console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
    // });

    await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputDevices[0].deviceId);
    await this.meetingSession.audioVideo.chooseAudioOutputDevice(audioOutputDevices[0].deviceId);

    //debugger;
    // this.localVideo = true;
    // const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
    // this.meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 15, 1400)
    // await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);
    // const videoElement = document.getElementById('myvideo');
    // this.meetingSession.audioVideo.startVideoPreviewForVideoInput(videoElement)



    const audioElement = document.getElementById('hear');
    this.meetingSession.audioVideo.bindAudioElement(audioElement);

    // const observer = {
    //   audioVideoDidStart: () => {
    //     console.log('Started');
    //   }
    // };

    // this.meetingSession.audioVideo.addObserver(observer);

    this.meetingSession.audioVideo.start();



    this.VideoControl();
  }

  audioControl() {
    // Mute
    //debugger
    const muted = this.meetingSession.audioVideo.realtimeIsLocalAudioMuted();
    if (muted) {
      //  console.log('You are muted');if audio muted then unmute
      this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
      alert('unmute')
    } else {
      //console.log('Other attendees can hear your audio');
      //if audio unmuted then force mute it
      this.meetingSession.audioVideo.realtimeMuteLocalAudio();
      alert('mute')
    }
  }
  async LocalVideoControl() {
    // Mute
    //debugger
    const videoElement = document.getElementById('myvideo');
    if (this.localVideo == true) {
      this.meetingSession.audioVideo.stopVideoPreviewForVideoInput(videoElement)
      this.localVideo = false;
    } else {
      const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
      await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);
      this.meetingSession.audioVideo.startVideoPreviewForVideoInput(videoElement)
      this.localVideo = true;
    }
  }

  async VideoControl() {
    //debugger;
    const videoElement = document.getElementById('myvideo1');

    // Make sure you have chosen your camera. In this use case, you will choose the first device.
    const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();

    // The camera LED light will turn on indicating that it is now capturing.
    // See the "Device" section for details.
    await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);

    const observer = {
      // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
      videoTileDidUpdate: tileState => {
        // Ignore a tile without attendee ID and other attendee's tile.
        if (!tileState.boundAttendeeId || !tileState.localTile) {

          return;
        }
        this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElement);

      }
    };

    this.meetingSession.audioVideo.addObserver(observer);
    this.meetingSession.audioVideo.startLocalVideoTile();

  }

  meetingSession2: any;


  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  leaveMeeting() {
   $('#main-container').hide();
   $('#quesdiv').hide();
   
       const observer = {
      audioVideoDidStop: sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.Left) {
          /*
            - You called meetingSession.audioVideo.stop().
            - When closing a browser window or page, Chime SDK attempts to leave the session.
          */
          console.log('You left the session');
          if(this.hdnPriority==""){
           // this.router.navigate(['./feedback'], { queryParams: { mid: this.midt } });
           this.QuitMeeting();
          }else{
            this.onlyStopRec();
          }
       
        } else {
          console.log('Stopped with a session status code: ', sessionStatusCode);
        }
      }
    };

    this.meetingSession.audioVideo.addObserver(observer);

    this.meetingSession.audioVideo.stop();
  }
  taskid: string;
  rec: boolean = true
  // btnclick: any = false
  async StartRec() {
    //debugger;
    // if (this.btnclick == false) {
    //   this.btnclick = true;
    if (this.rec == true) {
      this.rec = false
      var meetingUrl = window.location.href;

      await this.apiendpoint.startRecording(meetingUrl).then((res: any) => {
        console.log(res);
        //this.btnclick = false;
        if (res.failures) {
          alert('Record fail')
          this.rec = true
        } else {
          this.taskid = res;
          // alert("recording started!!")
          this.removeQuestions();
         
        }

      })
    }
    //  }
  }
  downloadurl: string
  stopdata: any;
  async StopRec() {//stop & restart recording
    //debugger;
    if (this.rec == false) {
      await this.apiendpoint.stopRecording(this.taskid).then((res: any) => {
        console.log(res);
        this.rec = true
        this.stopdata = res;
        this.getRecUrl(res)

      })
    }
  }
  async getRecUrl(res) {//generate download url
    //await this.sleep(4000);
    //this.taskid = "";
    let startdate = res.task.startedAt;
    let dt = startdate.split('T')[0].split('-')
    let year = dt[0];//yyyy
    let month = dt[1]//.replace('0', '');//mm without 0
    if (month.split("")[0] == 0) {
      month = month.split("")[1]
    }
    let date = dt[2]//.replace('0', '')//dd without 0
    if (date.split("")[0] == 0) {
      date = date.split("")[1]
    }
    let month1 = startdate.split('T')[0].split('-')[1];//mm with 0
    let date1 = startdate.split('T')[0].split('-')[2];//dd with 0
    let time = startdate.split('T')[1].split('.')[0]//startdate.split('T')[1].split(':');
    var i = 2;
    var rec = true;
    if (i == 2) {
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      // sec = parseInt(sec) + 3

      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }

      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';

     await  this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
          this.StopStartRecord();
          //alert(this.downloadurl);
        } else {
          // console.log("url not exists.")
          i++;
        }
      })
    }
    if (rec == true) {//i=3
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }
      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';
      await this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
         // alert(this.downloadurl);
          this.StopStartRecord()
        } else {
          // console.log("url not exists.")
          i++;
        }
      })

    }
    if (rec == true) {//i=4
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }
      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';
     await this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
          //alert(this.downloadurl);
          this.StopStartRecord()
        } else {
          //console.log("url not exists.")
          i++;
        }
      })
    }
    if (rec == true) {//i=5
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }
      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';
      await this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
         // alert(this.downloadurl);
          this.StopStartRecord()
        } else {
          console.log("url not exists." + i)
          this.getRecUrl(this.stopdata)
          // i++;
        }
      })
    }

  }
 async  StopStartRecord() { // save download url in db update 22-7-2021
    //debugger;
    var val = this.hdnPriority - 1;
    var mid = this.questionsData[val].requestid;
    var id = this.questionsData[val].id;
    //alert("hdnpriority"+this.hdnPriority)
    var data = {
      MeetingId: mid,
      candidateQuestionid: id,
      url: this.downloadurl
    }
    await this.apiendpoint.stopstartrecord(data).subscribe((res: any) => {
      console.log(res)
       //alert("test");
      // if (localStorage.getItem("status") == "leave") {
      //   localStorage.removeItem("status")
      //  this.QuitMeeting();
      // } else {
        this.StartRec();
      //}

    })
  }
async QuitMeeting(){
    await this.apiendpoint.QuitInterview(this.midt).subscribe((res: any) => {
     alert("interview completed!!")
     this.router.navigate(['./feedback'], { queryParams: { mid: this.midt } });
    })
}
// feedbck(){
//   this.router.navigate(['./feedback'], { queryParams: { mid: this.midt } });
// }
  async onlyStopRec() {
//alert("onlyStopRec")
   await this.apiendpoint.stopRecording(this.taskid).then((res: any) => {
      console.log(res);
      this.rec = true
      this.stopdata = res;
     this.getRecUrlstopRec(res)

    })
  }
 async  getRecUrlstopRec(res) {
    debugger;  
    //alert("getRecUrlstopRec")
    let startdate = res.task.startedAt;
    let dt = startdate.split('T')[0].split('-')
    let year = dt[0];//yyyy
    let month = dt[1]//.replace('0', '');//mm without 0
    if (month.split("")[0] == 0) {
      month = month.split("")[1]
    }
    let date = dt[2]//.replace('0', '')//dd without 0
    if (date.split("")[0] == 0) {
      date = date.split("")[1]
    }
    let month1 = startdate.split('T')[0].split('-')[1];//mm with 0
    let date1 = startdate.split('T')[0].split('-')[2];//dd with 0
    let time = startdate.split('T')[1].split('.')[0]//startdate.split('T')[1].split(':');
    var i = 2;
    var rec = true;
    if (i == 2) {
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      // sec = parseInt(sec) + 3

      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }

      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';

      await this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
          this.SavelastRecord();
         // alert(this.downloadurl);
        } else {
          // console.log("url not exists.")
          i++;
        }
      })
    }
    if (rec == true) {//i=3
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }
      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';
      await this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
         // alert(this.downloadurl);
          this.SavelastRecord()
        } else {
          // console.log("url not exists.")
          i++;
        }
      })

    }
    if (rec == true) {//i=4
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }
      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';
      await this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
          //alert(this.downloadurl);
          this.SavelastRecord()
        } else {
          //console.log("url not exists.")
          i++;
        }
      })
    }
    if (rec == true) {//i=5
      const offset = i;//add sec
      const dates = new Date(+new Date(`1970-01-01T${time}Z`) + offset * 1000);
      const newtime = [dates.getUTCHours(), dates.getUTCMinutes(), dates.getUTCSeconds()].map(n => n.toString().padStart(2, "0")).join(':');
      console.log(newtime);
      let hour = newtime.split(':')[0];
      let min = newtime.split(':')[1];
      let sec = newtime.split(':')[2];
      if (hour.split('')[0] == "0") {
        var hr = hour.split("")[1];
      } else {
        var hr = hour;
      }
      this.downloadurl = 'https://chime-meeting-sdk-714744135226-ap-south-1-recordings.s3.ap-south-1.amazonaws.com/' + year + '/' + month + '/' + date + '/' + hr + '/' + year + '-' + month1 + '-' + date1 + 'T' + hour + '%3A' + min + '%3A' + sec + '.mp4';
      await this.apiendpoint.checkUrl(this.downloadurl).then((res: any) => {
        console.log(res);
        if (res.status == 200) {
          console.log("url exists." + i)
          rec = false;
          this.stopdata = "";
          //alert(this.downloadurl);
          this.SavelastRecord()
        } else {
          console.log("url not exists." + i)
          this.getRecUrl(this.stopdata)
          // i++;
        }
      })
    }
this.QuitMeeting();
  }
  async SavelastRecord() {
    //debugger;
    //alert("SavelastRecord")
    var val = this.hdnPriority - 1;
    var mid = this.questionsData[val].requestid;
    var id = this.questionsData[val].id;
    var data = {
      MeetingId: mid,
      candidateQuestionid: id,
      url: this.downloadurl
    }
    await this.apiendpoint.stopstartrecord(data).subscribe((res: any) => {
      console.log(res)
       this.QuitMeeting();
    })
  }
  ///////////////////////////////
  questionsData: any;
  Priority: any = 1;
  MeetingId: any = '';
  totalques: any = '';
  qno: any = '';
  hdnPriority: any="";
  hdnCount: any;
  hdnReason: any;
  hdnThinkTime: any;
  hdnAnswerTime: any;
  startbtn() {
    this.StartRec();
    this.apiendpoint.getQues(this.midt).subscribe((res: any) => {
      //debugger;
      console.log(res);
      this.questionsData = res;
      this.totalques = this.questionsData.length;
      this.hid = false;
      $('#totalQues').text(this.questionsData[this.totalques - 1].priority);//toatal interview questions
      $('.QuesView').css('display', 'block');//add 03 sept 2020
      $('.QuesTxt').css('display', 'block');//add 03 sept 2020
      if (this.totalques == 1) {
        document.getElementById("aNextQuestion").style.display = "none";
      }
      this.hdnPriority = "1"
      this.qno = this.questionsData[0].priority;
      this.hdnCount = this.totalques;
      this.BindQuestions();
    })
  }

  BindQuestions() {
   // //debugger;
    let questionPriority = parseInt(this.hdnPriority);

    questionPriority = questionPriority - 1;
    $('#Qno').text(this.qno + '.');//qno 
    $('#currQues').text(this.qno);// current question
    this.qno++;
    $('#Question').text(this.questionsData[questionPriority].question);
    $('#thnktm').text(this.questionsData[questionPriority].thinktime + ' seconds(s)');
    $('#answertm').text(this.questionsData[questionPriority].answertime + ' seconds(s)');
    this.hdnThinkTime = this.questionsData[questionPriority].thinktime;
    this.hdnAnswerTime = this.questionsData[questionPriority].answertime;
    this.hdnReason = "Think";
    this.abc();
  }
  c: any;
  d: any;
  QuitStatus: any = false;
  countDownDate: any = new Date("Jan 4, 2019 10:05:00").getTime();

  abc() {
    let that = this;
    //debugger;
    document.getElementById("demo").innerHTML = "<b>" + 0 + "h " +
      0 + "m " + 0 + "s " + "</b>";
    let Reason = this.hdnReason;

    if (this.hdnPriority == this.questionsData.length) {
      $('.nextbtn').hide();
    }
    switch (Reason) {
      case "Think": {
        this.c = parseInt(this.hdnThinkTime);

        if ((this.hdnPriority > 1) && (this.hdnPriority < this.totalques + 1)) {
          this.StopRec()// stop & start recording
        } 
        // else {
        //  // this.removeQuestions();
        // }
      }

        break;
      case "Answer": {
        this.c = parseInt(this.hdnAnswerTime);

      }
        break;
    }
    this.d = new Date();
    this.d.setSeconds(this.d.getSeconds() + this.c);
    this.countDownDate = this.d;
    setInterval(function () {
      that.myCounter1();
    }, 1000);
  }
  myCounter1() {
    let now = new Date().getTime();
    let distance = this.countDownDate - now;
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    document.getElementById("demo").innerHTML = "<b>" + hours + "h " +
      minutes + "m " + seconds + "s " + "</b>";
    let dt = new Date()
    dt.setSeconds(0, 0);
    if (distance < 0) {
      ////debugger;
      if (this.hdnReason == "Think") {
        this.hdnReason = "Answer";
        this.abc();
      } else {
        //debugger;
        if (this.hdnReason == "Answer") {
          this.hdnReason = "";
          let priority1 = parseInt(this.hdnPriority) + 1;
          this.hdnPriority = priority1;
          let count = parseInt(this.hdnCount);
          if (count >= priority1)
            this.GetNextQuestion();
        } else {
          //quit Interview
          document.getElementById("demo").innerHTML = "<b>" + 0 + "h " +
            0 + "m " + 0 + "s " + "</b>";
          if (this.QuitStatus == false) {
             this.QuitStatus = true;
            //	stoprec();
            $('#main-container').hide();
            $('#quesdiv').hide();
            const observer = {
              audioVideoDidStop: sessionStatus => {
                const sessionStatusCode = sessionStatus.statusCode();
                if (sessionStatusCode === MeetingSessionStatusCode.Left) {
                  /*
                    - You called meetingSession.audioVideo.stop().
                    - When closing a browser window or page, Chime SDK attempts to leave the session.
                  */
                  console.log('You left the session');
                 
                    this.onlyStopRec();
                  
               
                } else {
                  console.log('Stopped with a session status code: ', sessionStatusCode);
                }
              }
            };
        
            this.meetingSession.audioVideo.addObserver(observer);
        
            this.meetingSession.audioVideo.stop();
            //this.onlyStopRec();
         }
        }

      }
    }

  }

  async removeQuestions() {//working
    // let val = $('#hdnPriority').val() - 1;
    // let mid = this.questionsData[val].requestid;
    // let id = this.questionsData[val].id;
     // alert("remove")
    var val = this.hdnPriority - 1;
    var mid = this.questionsData[val].requestid;
    var id = this.questionsData[val].id;

    this.apiendpoint.RemoveQuestion(mid,id).subscribe((res:any)=>{
     console.log(res)
    })
    // 	let xhttp = new XMLHttpRequest(); //update 13 august 2020
    // 	xhttp.onreadystatechange = function () {
    // 		if (this.readyState == 4 && this.status == 200) {
    // 			////debugger;

    // 		}
    // 	};
    // 	xhttp.open("POST", apiURL + "api/Video/RemoveAttemptedQuestion/" + mid + '/' + id, true);
    // 	xhttp.send();
  }

  GetNextQuestion() {
    //debugger;

    document.getElementById("demo").innerHTML = "<b>" + 0 + "h " +
      0 + "m " + 0 + "s " + "</b>"; //--now when question question is change bydefault value  timer is od:ohr:om:os 
    this.BindQuestions();
  }

  SkipOrNextQuestion() {
    ////debugger;
    let priority1 = parseInt(this.hdnPriority) + 1;
    let priority2 = parseInt(this.hdnPriority) + 2;
    this.hdnPriority = priority1;
    let count = parseInt(this.hdnCount);
    if (count < priority2) {
      $('.nextbtn').hide();
    }
    if (count >= priority1) {
      this.GetNextQuestion();
    }
  }

}
