import { Injectable } from '@angular/core';
import { HttpBackend, HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SkipInterceptorService {
  httpClient: any;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  postRequest(url: string, body: any, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this.httpClient.post(url, body, { headers });
    } else {
      request = this.httpClient.post(url, body);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err)
        return {};
      });
  }

  getRequest(url: string, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this.httpClient.get(url, { headers });
    } else {
      request = this.httpClient.get(url);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  }


  getCandidateData(url:string){
    let promise = new Promise((resolve, reject) => {
      this.httpClient.get(url)
          .toPromise()
          .then(
              res => { // Success
                console.log(res.json().results);
                resolve(res);
              },
              msg => { // Error
                reject(msg);
              }
          );
    });
    return promise;
  }
}
